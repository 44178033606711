.left{ text-align: left; }
.right{ text-align: right; }
.center{ text-align: center; }
.primary-bg{background-color: #fa4517 !important;}

/* add icon */
.add{position: fixed; right: 25px; bottom: 30px;}

/* payment */
.history{padding-left: 25px;}
.padding{padding-left: 10px; padding-right: 10px;}
.meal{font-size: small; margin-left: -20%; margin-top: -3%;}
.paid{font-size: small; margin-right: -10%; margin-top: -3%;}
.delete{ font-size: xx-large; } 

/* navbar */
.float{position: sticky; top: 0; z-index: 9999; }
.link{text-decoration: none;}
.float-right{float: right;}
.head{font-size: x-large;}

/* accordion */
.accordion-content { overflow: hidden; max-height: 0; transition: max-height 0.1s ease-in-out; }
.accordion-content.open { max-height: 9999px; transition: max-height 0.1s ease-in-out;}

/* 3-dot Bouncing Loader */
.loader {
    display: inline-block;
    margin-top: 20px;
  }
  
  .loader span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #fa4517;
    animation: bounce 1.2s infinite ease-in-out;
  }
  
  .loader span:nth-child(1) {
    animation-delay: -0.4s;
  }
  
  .loader span:nth-child(2) {
    animation-delay: -0.2s;
  }
  
  .loader span:nth-child(3) {
    animation-delay: 0s;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  